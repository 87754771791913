/* App.css */

body {
  background-color: #f8f9fa;
}

.container {
  max-width: 800px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

h1 {
  color: #007bff;
  text-align: center;
  margin-bottom: 30px;
}

.file-upload-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.file-upload-input {
  border: 2px dashed #007bff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-upload-input:hover {
  background-color: #f1f8ff;
}

.file-info-card {
  background-color: #e9ecef;
  border: none;
  border-radius: 8px;
}

.file-info-card .card-title {
  color: #007bff;
}

.generate-quote-btn {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
}

.quote-result {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

@media (max-width: 576px) {
  .container {
    padding: 15px;
  }
}

.file-upload-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.file-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  border: 2px dashed #007bff;
  border-radius: 8px;
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-label:hover {
  background-color: #f1f8ff;
}

.preview-container {
  text-align: center;
}

.preview-image {
  max-width: 100%;
  max-height: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}